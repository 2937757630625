import React 		  from "react"
import Layout		  from "../components/layout"

const Anfrage = () => (
	<Layout head_gif = "location_bhb.png">
		<td align="center" width="539" valign="top">
		  <h1>Anfragen</h1>
			<p>Ihre Daten werden SSL-verschlüsselt übertragen. Bitte beachten Sie unsere Datenschutzerklärung.</p>
			<form method="post" action="cgi-bin/anfrage.cgi" onSubmit="return checkit(this)" class="formular" >
				<table width="500" border="0" align="left" cellpadding="0" cellspacing="0">
					<tr>
						<td height="30" colspan="4" align="left" valign="middle"><strong>Persönliche Daten:</strong></td>
					</tr>
					<tr>
						<td width="130" height="35" align="left" valign="middle" >Name*</td>
						<td width="240" height="35" align="left" valign="middle"><strong>
							<input name="rw_name" id="rw_name" size="28" type="text" required/>
						</strong></td>
						<td width="129" height="35" align="left" valign="middle">Telefon:</td>
						<td width="501" height="35" align="left" valign="middle"><strong>
							<input name="telefon" id="telefon" size="28" type="text" />
						</strong></td>
					</tr>
					<tr>
						<td height="35" align="left" valign="middle">Stra&szlig;e</td>
						<td height="35" align="left" valign="middle"><strong>
							<input name="strasse" id="strasse" size="28" type="text" />
						</strong></td>
						<td height="35" align="left" valign="middle">Fax:</td>
						<td height="35" align="left" valign="middle"><strong>
							<input name="fax" id="fax" size="28" type="text" />
						</strong></td>
					</tr>
					<tr>
						<td height="35" align="left" valign="middle">PLZ/Ort</td>
						<td height="35" align="left" valign="middle"><strong>
							<input name="ort" id="ort" size="28" type="text" />
						</strong></td>
						<td height="35" align="left" valign="middle">E-Mail*</td>
						<td height="35" align="left" valign="middle"><strong>
							<input name="re_email" type="email" id="re_email" class="datum" size="28" value="" required/>
						</strong></td>
					</tr>
					<tr>
						<td height="40" align="left" valign="middle">&nbsp;</td>
						<td height="40" align="left" valign="middle">&nbsp;</td>
						<td height="40" align="left" valign="middle">&nbsp;</td>
						<td height="40" align="left" valign="middle">&nbsp;</td>
					</tr>
					<tr>
						<td height="35" colspan="4" align="left" valign="middle"><strong>Reiseinformationen</strong>:</td>
					</tr>
					<tr>
						<td height="35" align="left" valign="middle">Anreise</td>
						<td height="35" align="left" valign="middle"><strong>
							<input name="anreise" id="anreise" class="anreise" size="28" type="date" />
						</strong></td>
						<td height="35" align="left" valign="middle">Erwachsene</td>
						<td height="35" align="left" valign="middle"><strong>
							<input name="erwachsene" id="erwachsene" size="28" type="text" />
						</strong></td>
					</tr>
					<tr>
						<td height="35" align="left" valign="middle">Abreise</td>
						<td height="35" align="left" valign="middle"><strong>
							<input name="abreise" id="abreise" class="abreise" size="28" type="date" />
						</strong></td>
						<td height="35" align="left" valign="middle">Kinder</td>
						<td height="35" align="left" valign="middle"><strong>
							<input name="kinder" id="kinder" size="28" type="text" />
						</strong></td>
					</tr>
					<tr>
						<td height="35" align="left" valign="middle">&nbsp;</td>
						<td height="35" align="left" valign="middle">&nbsp;</td>
						<td height="35" align="left" valign="middle">Alter der Kinder</td>
						<td height="35" align="left" valign="middle"><strong>
							<input name="alterkinder" id="alterkinder" size="28" type="text" />
						</strong></td>
					</tr>
					<tr>
						<td height="35" align="left" valign="middle">&nbsp;</td>
						<td height="35" align="left" valign="middle">&nbsp;</td>
						<td height="35" align="left" valign="middle">&nbsp;</td>
						<td height="35" align="left" valign="middle">&nbsp;</td>
					</tr>
					<tr>
						<td height="35" colspan="4" align="left" valign="middle">Ihre Nachricht an uns:
							<input name="_redirect" value="../danke.htm" type="hidden" />
							<input value="anfrage.txt" name="_send_email" type="hidden" /></td>
					</tr>
					<tr>
						<td height="35" colspan="4" align="left" valign="top">
					  	<strong>
						 	<div name="w_bemerkungen" cols="74" rows="8" id="w_bemerkungen" style={{width:"700px"}} ></div>
						  </strong>
						</td>
					</tr>
					<tr>
						<td colspan="4" align="left">&nbsp;</td>
					</tr>
					<tr>
						<td colspan="4" align="left"><p>
							<strong>
							<input value="Nachricht senden" type="submit" class="btn btn-outline-danger" />
							</strong></p>
							<ul>
								<ul><li>Die mit einem * markierten Felder sind Pflichtfelder!</li></ul>
							</ul></td>
					</tr>
				</table>
			</form>
    </td>
	</Layout>
);

export default Anfrage;
